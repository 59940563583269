@import '~antd/dist/antd.css';
.App {
    /* padding-top: 70px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-weight: 300;
    color: white;
}

.wrapper {
    width: 60vw;
}

.search-detail {
    width: 50%!important;
}


/* @media only screen and (max-width: 1000px) {
    .wrapper {
        width: 80vw;
    }
} */

.search {
    margin-top: 30px;
    /* margin-bottom: 45px; */
    /* position: sticky; */
    top: 0;
    background: white;
    z-index: 1;
    /* padding: 30px; */
    padding-bottom: 10px;
    box-shadow: #00000024 0px 4px 10px;
    border-radius: 4px;
    padding: 5px;
}

.search-input input,
.search-input button {
    /* font-size: 18px; */
    /* height: 50px!important; */
}

.search .ant-select {
    /* font-size: 18px; */
    /* height: 30px!important; */
}

.search .search-filters {
    display: flex;
    align-items: center;
}

.search .search-filters .ant-select-selector {
    padding-left: 24px;
}

.search .search-filters .prefixIcon {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 8px;
}

.search .search-filters .filter-control {
    position: relative;
    width: 33%;
}

.search .ant-select-clear {
    font-size: 17px;
    right: 15px;
    top: 15px;
}

.search-list {
    /* padding: 50; */
    width: 100%;
    text-align: left;
}

.search-thumbnail {
    height: 200px;
    width: 250px;
    object-fit: cover;
}

.search-thumbnail-default {
    /* min-height: 120px;
    max-height: 150px; */
    overflow: hidden;
    display: block;
    width: 250px;
    /* border-radius: 10px;
    background-color: #e6e6e6; */
}

.search-list .ant-list-item {
    cursor: pointer;
}

.search-list .ant-list-item:hover {
    background: #1890ff26;
}

.search-list .ant-list-item:active {
    background: #1890ff7a;
}

.cardMeta {
    text-align: left;
    height: 200px;
    overflow: hidden;
    /* box-shadow: inset 0 -24px 11px -25px #00000069; */
    border-radius: 4px;
    text-overflow: ellipsis;
}

.cover {
    /* height: 100px; */
}

.footer {
    width: '100%';
    background: white;
}

@media only screen and (max-width: 550px) {
    .ant-list-item-meta {
        flex-direction: column;
    }
    .ant-list-item-meta-content {
        width: 100%;
    }
    .search .search-filters {
        flex-direction: column;
    }
    .search .search-filters .filter-control {
        width: 100%;
    }
}

@media only screen and (max-width: 1500px) {
    .wrapper {
        width: 95vw;
    }
    .search-detail {
        width: 90vw!important;
    }
}

.map-circle-divicon {
    display: block;
    min-height: 50px;
    min-width: 50px;
    background: white;
    border-radius: 100%;
    text-align: center;
    font-size: 15px;
    color: black;
    font-weight: bold;
    line-height: 50px;
}